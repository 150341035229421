import React, { Component } from 'react'
import { Layout, Menu, Avatar, Badge, Button, Progress, Tag, Tooltip } from 'antd'
import {
  BellOutlined,
  DownOutlined,
  HomeOutlined,
  SettingOutlined,
  DollarOutlined,
  LogoutOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons'

import history from '../../history'

import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { actions as SettingsActions } from '../../store/SettingsReducer'
import { actions as NotificationsActions } from '../../modules/Notifications/reducer'
import { actions as MonitorActions } from '../../store/MonitorReducer'

import logo from './logo.png'

const { Header } = Layout
const { SubMenu } = Menu

class AccountSettings extends Component {
  renderSignupMessage = () => {
    return [
      <Menu.Item key={'/login'}>
        <Link to={'/login'}>Log In</Link>
      </Menu.Item>,
      <Menu.Item key={'/sign-up'}>
        <Link to={'/sign-up'}>Sign Up For 7 Day Trial</Link>
      </Menu.Item>,
    ]
  }

  renderNotificationsMessage = (notifications = []) => {
    return (
      <Menu.Item key="11" className="header-notifications">
        <Link to={'/app/notifications'}>
          <Badge
            count={notifications.filter((n) => n.status === 'new').length || 0}
            className="notify-item"
          >
            <BellOutlined style={{ fontSize: 19 }} />
          </Badge>
        </Link>
      </Menu.Item>
    )
  }

  renderTrialMessage = (isTrial, isExpired) => {
    if (!isTrial) return null
    const message = isExpired
      ? 'Your Trial has expired. Activate Now'
      : 'Your Trial is expiring soon. Activate Now'
    return (
      <Menu.Item key="10">
        <Link to={'/app/pricing'}>{message}</Link>
      </Menu.Item>
    )
  }

  renderImpersonate = (monitor) => {
    if (!monitor.isImpersonateMode) return null
    return (
      <Menu.Item key="12" onClick={this.props.monitorImpersonateOff}>
        Impersonating User: {monitor.impersonateUserId}
      </Menu.Item>
    )
  }

  renderFreeAccount = (plan, planCredits) => {
    if (!(plan === '' && planCredits > 0)) return null
    return (
      <div className="header-free-account-notice">
        <div className="header-free-account-notice-text">
          You are on the Free plan, reports are limited
        </div>
        <div>
          <span className="header-free-account-tooltip">
            <Tooltip title="All reports are limited to the first few broken links we find on your website. Upgrade to get full reports.">
              <span style={{ marginLeft: 5, marginRight: 10 }}>
                <QuestionCircleTwoTone />
              </span>
            </Tooltip>
          </span>
          <Button
            onClick={() => history.push('/app/pricing/')}
            size="small"
            style={{ marginLeft: '15px', fontWeight: 600 }}
            type="primary"
          >
            Upgrade Now
          </Button>
        </div>
      </div>
    )
  }

  renderUserMenu = (userMenuProps) => {
    const { currentUserInitials, planCredits, credits } = userMenuProps
    const MenuTitle = () => (
      <span>
        <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', marginRight: 15 }}>
          {currentUserInitials}
        </Avatar>
        <span>Account</span>
        <DownOutlined style={{ marginLeft: 10 }} />
      </span>
    )
    const currentCredits = credits < 0 ? 0 : credits

    return (
      <SubMenu key="sub1" title={<MenuTitle />} open>
        <Menu.Item
          key="0"
          style={{ height: 80 }}
          className="menu-credits-point"
          onClick={() => this.props.history.push('/app/account/usage/')}
        >
          <div style={{ fontSize: 13, marginBottom: '-20px' }}>
            <div style={{ marginBottom: '-15px' }}>Available credits: </div>
            {Math.floor(currentCredits)} / {planCredits}
          </div>
          <Progress percent={(credits / planCredits) * 100} showInfo={false} />
        </Menu.Item>
        <Menu.Item
          key="20"
          onClick={() => this.props.history.push('/app/dashboard/')}
          icon={<HomeOutlined />}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="30"
          onClick={() => this.props.history.push('/app/account/usage/')}
          icon={<SettingOutlined />}
        >
          Settings
        </Menu.Item>
        <Menu.Item
          key="50"
          onClick={() => window.open('https://amzwatcher.com/affiliate-program/')}
          icon={<DollarOutlined />}
        >
          Affiliates
        </Menu.Item>
        <Menu.Item key="60" onClick={this.props.signOutUser} icon={<LogoutOutlined />}>
          Log Out
        </Menu.Item>
      </SubMenu>
    )
  }

  render() {
    const { settings, notifications, monitor } = this.props
    const { name, isTrial, permissions, credits, planCredits, plan } = settings
    const { notifications: n } = notifications
    const currentUserInitials = name ? `${name[0]}` : 'U'
    const isAdmin = permissions.allowedAdmin
    const userMenuProps = {
      currentUserInitials,
      isAdmin,
      credits,
      isTrial,
      planCredits,
    }

    return (
      <Menu
        theme="light"
        mode="horizontal"
        style={{ lineHeight: '64px', border: 'none' }}
        selectable={false}
        className="header-right"
      >
        {this.renderFreeAccount(plan, planCredits)}
        {this.renderImpersonate(monitor)}
        {this.renderTrialMessage(isTrial, !permissions.isTrialActive)}
        {this.renderNotificationsMessage(n)}
        {this.renderUserMenu(userMenuProps)}
      </Menu>
    )
  }
}

const getActiveId = (menu, path) => {
  return menu.filter((i) => i.url === path).map((i) => i.url)
}

const DelinquentNotice = (props) => {
  const { name, firstFailedCharge } = props.settings

  if (!firstFailedCharge) return null

  let message = (
    <span>
      {name || 'Hey there'}, your payment has failed. Please update your credit card information and
      pay your outstanding invoice
    </span>
  )

  return (
    <div className="header-notice">
      {message}
      <Button
        onClick={() => history.push('/app/account/billing/')}
        size="small"
        style={{ marginLeft: '15px', fontWeight: 600 }}
        type="primary"
      >
        Pay Now
      </Button>
    </div>
  )
}

const TrialMessage = (props) => {
  // const { fa } = props;

  if (true) return null
  let message = (
    <span>You are on a free plan with limited reports, upgrade to get a full version</span>
  )

  return (
    <div className="header-free-trial">
      {message}
      <Button
        onClick={() => history.push('/app/pricing/')}
        size="small"
        style={{ marginLeft: '15px', fontWeight: 600 }}
        type="primary"
      >
        Upgrade Now
      </Button>
    </div>
  )
}

class HeaderComponent extends Component {
  state = { visible: false }
  componentDidMount() {
    this.props.fetchNotifications()
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = (e) => {
    console.log(JSON.stringify({ message: e.message, stack: e.stack }))
    this.setState({
      visible: false,
    })
  }

  handleCancel = (e) => {
    console.log(JSON.stringify({ message: e.message, stack: e.stack }))
    this.setState({
      visible: false,
    })
  }

  showBetaModal = () => {
    console.log('show')
    this.setState({
      visible: true,
    })
  }

  render() {
    const { userId, emailVerified, permissions, plan, planCredits } = this.props.settings
    if (!userId || !emailVerified) return null
    const isAdmin = permissions.allowedAdmin
    const isFree = plan == '' && planCredits > 0
    const userMenu = [
      { title: 'Dashboard', url: '/app/dashboard/', isExternal: false },
      { title: 'Help', url: 'https://amzwatcher.com/knowledge-base/', isExternal: true },
      isAdmin && { title: 'Tools', url: '/app/admin/', isExternal: false },
      (isAdmin || isFree) && { title: 'Pricing', url: '/app/pricing/', isExternal: false },
    ].filter((m) => !!m)
    const selectedKeys = getActiveId(userMenu, this.props.history.location.pathname)
    return (
      <div style={{ zIndex: 1 }}>
        <DelinquentNotice {...this.props} />
        <div className="header-container">
          <Header className="header-left" style={{ background: '#fff', padding: 0 }}>
            <div className="logo">
              <img src={logo} alt="logo" width="200px" />
            </div>
            <Menu
              selectedKeys={selectedKeys}
              theme="light"
              mode="horizontal"
              style={{ lineHeight: '64px', border: 'none', minWidth: 400 }}
            >
              {userMenu.map((m, id) => {
                if (m.isExternal)
                  return (
                    <Menu.Item key={m.url}>
                      <span onClick={() => window.open(m.url)}>{m.title}</span>
                    </Menu.Item>
                  )
                return (
                  <Menu.Item key={m.url}>
                    <Link to={m.url}>{m.title}</Link>
                  </Menu.Item>
                )
              })}
            </Menu>
          </Header>
          <AccountSettings {...this.props} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = {
  ...SettingsActions,
  ...NotificationsActions,
  ...MonitorActions,
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HeaderComponent)
